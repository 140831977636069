import React, { useEffect, useState } from "react";
import SEO from "../../components/Seo";
import { axios } from "../../config/axios";
import Navbar from "@organism/Navbar/Navbar";
import Footer from "@organism/Footer/Footer";
import ArticleCardX from "@molecules/ArticleCardX";
import ScrollTopButton from "@molecules/ScrollTopButton";
import ArticleContainer from "@organism/ArticlesList/article-container";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import BackLink from "../../components/atoms/BackLink";

const CeosInZoom = ({location}) => {    
    const breakpoints = useBreakpoint();
    const [coursesBlogs, setCoursesBlogs] = useState([]);
    useEffect(() => {
        getAllBlogs();        
    }, []);

    const getAllBlogs = async () => {
        let { data } = await axios.get("/posts", { params: { _limit: -1 }});
        let sortedData = data.sort((a, b) => new Date(b.published_at) - new Date(a.published_at))
        const postArr = sortedData.map(el => {
          let post_translations = {};
          el.post_translations.forEach(el => {
            post_translations = { ...post_translations, [el.language]: { ...el } };
          });
          return { ...el, post_translations };
        });                   
        const courses = postArr.filter(el => el.post_category.name === "Others");
        setCoursesBlogs(courses);
    };


    const { t } = useTranslation();
    const { language } = useI18next();
    return (
        <>
          <SEO
            title={t('CONTENT.FEATURING.COURSES')}
            description={t('SEO.CONTENT.DESCRIPTION')}
            originUrl={location.origin}
            siteUrl={location.href}
            lang={language}
          />  
          <Navbar location={location} platform ideasVisible={false}/>
          <div style={{
              paddingTop: breakpoints.sm ? "20%" : "10%",
              paddingRight: "3%",
              paddingLeft: "3%",
              backgroundColor: "#fff"
            }}>
            <ArticleContainer 
              name={t("CONTENT.FEATURING.COURSES")} 
              id='CEOSINZOOM'    
              black          
            >
              <div style={{
                display: "flex",
                flexFlow: "column wrap"
              }}>
                {coursesBlogs.length > 0 &&
                    coursesBlogs.map(el => (
                    <ArticleCardX
                        key={el.published_at}
                        bgImg={el.hero_image && el.hero_image.url}
                        title={el.post_translations[language]?.title}
                        description={el.post_translations[language]?.description}
                        date={el.published_at}
                        time={el.read_time}
                        el={el}
                        allPosts={coursesBlogs}
                    />
                    ))}
              </div>
            </ArticleContainer>
            <BackLink />
          </div>
          <ScrollTopButton />
          <Footer />
        </>
    )
}

export default CeosInZoom
